exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-albert-camus-jsx": () => import("./../../../src/pages/albert-camus.jsx" /* webpackChunkName: "component---src-pages-albert-camus-jsx" */),
  "component---src-pages-aviso-de-privacidad-jsx": () => import("./../../../src/pages/aviso-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-jsx" */),
  "component---src-pages-como-nuestras-emociones-moldean-la-percepcion-del-mundo-jsx": () => import("./../../../src/pages/como-nuestras-emociones-moldean-la-percepcion-del-mundo.jsx" /* webpackChunkName: "component---src-pages-como-nuestras-emociones-moldean-la-percepcion-del-mundo-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-convertirnos-en-budas-jsx": () => import("./../../../src/pages/convertirnos-en-budas.jsx" /* webpackChunkName: "component---src-pages-convertirnos-en-budas-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-enojo-y-miedo-jsx": () => import("./../../../src/pages/enojo-y-miedo.jsx" /* webpackChunkName: "component---src-pages-enojo-y-miedo-jsx" */),
  "component---src-pages-estados-y-factores-mentales-jsx": () => import("./../../../src/pages/estados-y-factores-mentales.jsx" /* webpackChunkName: "component---src-pages-estados-y-factores-mentales-jsx" */),
  "component---src-pages-existencialismo-jsx": () => import("./../../../src/pages/existencialismo.jsx" /* webpackChunkName: "component---src-pages-existencialismo-jsx" */),
  "component---src-pages-friedrich-nietzsche-jsx": () => import("./../../../src/pages/friedrich-nietzsche.jsx" /* webpackChunkName: "component---src-pages-friedrich-nietzsche-jsx" */),
  "component---src-pages-fundamentos-filosofia-budista-jsx": () => import("./../../../src/pages/fundamentos-filosofia-budista.jsx" /* webpackChunkName: "component---src-pages-fundamentos-filosofia-budista-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-la-vida-liquida-jsx": () => import("./../../../src/pages/la-vida-liquida.jsx" /* webpackChunkName: "component---src-pages-la-vida-liquida-jsx" */),
  "component---src-pages-viaje-a-ixtlan-jsx": () => import("./../../../src/pages/viaje-a-ixtlan.jsx" /* webpackChunkName: "component---src-pages-viaje-a-ixtlan-jsx" */),
  "component---src-pages-zaratustra-jsx": () => import("./../../../src/pages/zaratustra.jsx" /* webpackChunkName: "component---src-pages-zaratustra-jsx" */)
}

